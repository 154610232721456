import { I_ThemeAction } from "../interfaces/theme";

export const toggleLightTheme = (): I_ThemeAction => ({
  type: "light",
  payload: "light"
})

export const toggleDarkTheme = (): I_ThemeAction => ({
  type: "dark",
  payload: "dark"
})
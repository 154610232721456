import React, { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

const Announcement = () => {
  const { theme }: RootStateOrAny = useSelector((state: RootStateOrAny) => state);

  const [visible, setVisible] = useState(true);

  const closeBanner = () => setVisible(false);

  if (!visible) return null;

  return (
    <div className={`announcement ${theme}`}>
      <a href="https://vently.ai">
        Learn more about my startup, <span>Vently</span>.
      </a>
      <span className="close" onClick={closeBanner}></span>
    </div>
  );
};

export default Announcement;

import { AnyAction } from "redux"
import { ThunkAction, ThunkDispatch } from "redux-thunk"

export const openNavigationMenu = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        dispatch({ type: "OPEN_NAVIGATION_MENU" })
    }
}

export const closeNavigationMenu = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        dispatch({ type: "CLOSE_NAVIGATION_MENU" })
    }
}
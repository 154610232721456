import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface I_SEOProps {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
  meta?: string;
}

const SEO = ({ title, description, image, article }: I_SEOProps) => {
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultFavicon,
    twitterUsername,
    keywords,
    lang
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}`,
    lang: lang || "en"
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{lang}}
    >
      <title>{title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={keywords} />
      <meta name="image" content={seo.image} />
      <link rel="canonical" href={seo.url} />

      {seo.url && (
        <meta property="og:url" content={seo.url} />
      )}

      {(article ? true : null) && (
        <meta property="og:type" content="article" />
      )}

      {seo.title && (
        <meta property="og:title" content={seo.title} />
      )}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && (
        <meta property="og:image" content={seo.image} />
      )}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && (
        <meta name="twitter:title" content={seo.title} />
      )}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && (
        <meta name="twitter:image" content={seo.image} />
      )}

      <meta property="og:site_name" content="BRIAN NJOGU" />
      
      <link rel="icon" type="image/png" href={defaultFavicon} sizes="16x16" />

    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
        keywords
        defaultFavicon: favicon
        lang
      }
    }
  }
`
import React, { ReactNode } from 'react';
import Header from '../header';
import { RootStateOrAny, useSelector } from 'react-redux';
import * as styles from '../../../styles/components/shared/layout/index.module.css';
import Footer from '../footer';

interface I_LayoutProps {
  children: Element[] | Element & ReactNode | ReactNode;
  style?: any;
}

export interface I_AllRefs {
  intro?: any;
  about?: any;
  work?: any;
  projects?: any;
  blog?: any;
  contact?: any;
}

const Layout: React.FC<I_LayoutProps> = ({ children, style }: I_LayoutProps): JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state.theme);

  const allRefs: I_AllRefs = {
    intro: React.useRef<any>(null),
    about: React.useRef<any>(null),
    work: React.useRef<any>(null),
    projects: React.useRef<any>(null),
    blog: React.useRef<any>(null),
    contact: React.useRef<any>(null),
  };

  const childrenWithExtraProps = React.Children.map(children, (child: any) => {
    return React.cloneElement(child, allRefs);
  });

  const headerProps = {
    allRefs: allRefs,
  };

  return (
    <div className={`${styles.body} ${styles[currentTheme]}`} style={style || {}}>
      <Header {...headerProps} />
      <main className={styles.main}>{childrenWithExtraProps}</main>
      <Footer />
    </div>
  );
};

export default Layout;

import { I_ObjectKeys } from "../config/constants"

export const COLORS_LIGHT: I_ObjectKeys = {
  bg: "#e4fbff",
  primaryTxt: "#000"
}

export const COLORS_DARK: I_ObjectKeys = {
  bg: "#440a67",
  primaryTxt: "#fff"
}

export const COLORS_SHARED: I_ObjectKeys = {
  light: "#e6e6e6",
  dark: "#333",
  primary: "#06003c",
  secondary: "#f91650",
  pink: "#ff1e56"
}
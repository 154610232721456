import React, { Dispatch } from 'react';
import { connect, RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import FadeIn from 'react-fade-in';
import SunIcon from '@material-ui/icons/WbSunnyOutlined';
import MoonIcon from '@material-ui/icons/Brightness2Outlined';
import { closeNavigationMenu, openNavigationMenu } from '../../../actions/header';
import { toggleDarkTheme, toggleLightTheme } from '../../../actions/theme';
import { isLightTheme, scrollToSection } from '../../../util';
import SvgLogo from '../logos/svg-logo';
import * as styles from './../../../styles/components/shared/header/index.module.css';
import { COLORS_SHARED } from '../../../theme';
import { I_HeaderProps } from './interfaces';
import Announcement from '../announcement';

interface I_GlobalStateProps {
  theme: string;
  navigationActive: boolean;
}

interface I_AdditionalProps {
  openNavigationMenu: () => Promise<void>;
  closeNavigationMenu: () => Promise<void>;
}

type T_HeaderProps = I_HeaderProps & I_AdditionalProps;

const Header: React.FC<T_HeaderProps> = ({
  allRefs,
  openNavigationMenu,
  closeNavigationMenu,
}: T_HeaderProps): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const globalState: RootStateOrAny = useSelector((state: RootStateOrAny) => state);
  const { navigationActive, theme }: I_GlobalStateProps = globalState;

  const toggleNavigation = (): void => {
    navigationActive ? closeNavigationMenu() : openNavigationMenu();
  };

  const toggleTheme = (): void => {
    dispatch(isLightTheme(theme) ? toggleDarkTheme() : toggleLightTheme());
  };

  const closeMenuAndScrollToSection = async (section: string): Promise<void> => {
    await closeNavigationMenu();
    setTimeout(async () => await scrollToSection(allRefs, section), 500);
  };

  const headerItems: string[] = [
    'intro',
    'about',
    'work',
    // "projects", "blog",
    'contact',
  ];

  const headerThemeStyles = styles[`cd_header_${theme}`];
  const menuIconThemeStyles = styles[`menu_icon__${theme}`];
  const navThemeStyles = styles[`nav_${theme}`];
  const menuItemThemeStyles = styles[`nav__list_item_a_${theme}`];

  return (
    <div className={`${styles.body} ${navigationActive ? styles.nav_active : ''}`}>
      <header className={`${styles.cd_header} ${headerThemeStyles}`}>
        {!navigationActive && <Announcement />}
        <div className={styles.header_wrapper}>
          <FadeIn delay={50}>
            <div className={`${styles.logo_wrap} hoverable`}>
              <SvgLogo />
            </div>
          </FadeIn>

          <FadeIn delay={50}>
            <div onClick={toggleNavigation} className={`${styles.nav_but_wrap} hoverable`}>
              <div className={`${styles.menu_icon}`}>
                <span
                  className={`${styles.menu_icon__line} ${styles.menu_icon__line_left} ${menuIconThemeStyles}`}
                ></span>
                <span className={`${styles.menu_icon__line} ${menuIconThemeStyles}`}></span>
                <span
                  className={`${styles.menu_icon__line} ${styles.menu_icon__line_right} ${menuIconThemeStyles}`}
                ></span>
              </div>
            </div>
          </FadeIn>

          <FadeIn delay={50}>
            <div onClick={toggleTheme} className={`${styles.nav_but_wrap_theme_toggler} hoverable`}>
              {isLightTheme(theme) ? (
                <MoonIcon fontSize={'large'} style={{ color: COLORS_SHARED.dark }} />
              ) : (
                <SunIcon fontSize={'large'} style={{ color: COLORS_SHARED.light }} />
              )}
            </div>
          </FadeIn>
        </div>
      </header>

      <div className={`${styles.nav} ${navThemeStyles}`}>
        <div className={styles.nav__content}>
          <ul className={styles.nav__list}>
            {headerItems.map((section: string, index: number) => {
              let isActive: boolean = false; // isSectionVisible(allRefs, section);
              return (
                <li
                  key={index}
                  className={`${styles.nav__list_item} ${isActive && styles.active_nav} hoverable`}
                  onClick={async (): Promise<void> => {
                    await closeMenuAndScrollToSection(section);
                  }}
                >
                  <a
                    href="#"
                    className={`${menuItemThemeStyles}`}
                    onClick={(e) => e.preventDefault()}
                  >
                    {section}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    openNavigationMenu: () => dispatch(openNavigationMenu()),
    closeNavigationMenu: () => dispatch(closeNavigationMenu()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);

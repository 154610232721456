import React from 'react'
import Fade from "react-reveal/Fade"
import { I_FooterProps } from './interfaces'
import * as footerStyles from "../../../styles/components/shared/footer/index.module.css"
import { RootStateOrAny, useSelector } from 'react-redux'
import { FOOTER_LOGOS, I_ImageProps } from '../../../config/constants'
import { isLightTheme } from '../../../util'
import { COLORS_SHARED } from '../../../theme'
import { Linkedin, Github, Twitter, Instagram, EnvelopeFill } from 'react-bootstrap-icons'

const Footer: React.FC<I_FooterProps> = ({}: I_FooterProps): JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state?.theme);

  const logoFill: string = isLightTheme(currentTheme) ? COLORS_SHARED.dark : "#ff1e56";

  const getIcons = React.useCallback((props: I_ImageProps) => {
    switch(props?.name){
      case "Github":
        return <Github color={logoFill} size={19} />;
      case "LinkedIn":
        return <Linkedin color={logoFill} size={19} />;
      case "Gmail":
        return <EnvelopeFill color={logoFill} size={19} />;
      case "Twitter":
        return <Twitter color={logoFill} size={19} />;
      case "Instagram":
        return <Instagram color={logoFill} size={19} />;
      default:
        return <React.Fragment />
    }
  }, [currentTheme]);

  return (
    <div className={`${footerStyles.container}`}>
        <div className={`${footerStyles.copyright_div}`}>
                  <span className={`${footerStyles.copyright} ${footerStyles[`copyright_${currentTheme}`]}`}>
                    © {new Date().getFullYear()} Brian Njogu · All Rights Reserved.
                  </span>
              </div>
        <div className={`${footerStyles.social_media_div}`}>
            {FOOTER_LOGOS?.map((props: I_ImageProps, index: number) => {
                const isLastIteration: boolean = index === FOOTER_LOGOS.length - 1;
                return (
                    <a key={index} href={props?.href} target={"_blank"} className={isLastIteration ? "" : "mr-4"}>
                      {getIcons(props)}
                    </a>
                )
              })}
          </div>
    </div>
  )
}

export default Footer;